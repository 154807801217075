<template>
  <div
    class="viz-component"
    ref="component"
    v-resize="onResize"
    :class="[$tvaMq]"
  >
    <div
      :class="['background', { isTransitioning: getIsTransitioning }]"
      :style="{
        top:
          (getVizElSelected && getVizElSelected.data.progress
            ? yScalePos(0)
            : 0) + 'rem',
        height:
          yScaleHeight(hierarchyData.data.weight[vizSwitchSelRes]) + 'rem',
        transitionDuration: transitionDuration / 1000 + 's',
      }"
    ></div>
    <div
      class="viz-component__container"
      :style="{
        width: `${width}rem`,
        height: `${height}rem`,
        transform: `translate3d(${0}rem,0,0)`,
      }"
    >
      <VizElement
        :elements="hierarchyData.children"
        :elementsWidth="elementsWidth"
        :xScale="xScale"
        :yScalePos="yScalePos"
        :yScaleHeight="yScaleHeight"
        :isTransitioning="getIsTransitioning"
        :transitionDuration="transitionDuration"
        :vizSwitchSel="vizSwitchSel"
        :highestDepth="hierarchyData.height"
        @click="setVizSelection($event)"
      />
      <!-- <HoverBox v-if="!!getHoverData" /> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// Mixins
import genericQueryMethods from "@/mixins/genericQueryMethods";
import queryListener from "@/mixins/queryListener";
// components
import VizElement from "@/components/treemap-viz/VizElement";
// libs
import * as d3Scale from "d3-scale";
import resize from "vue-resize-directive";
import { getFontsize } from "../../utilities/functions/getFontsize";
// import HoverBox from "../HoverBox.vue";

export default {
  mixins: [genericQueryMethods, queryListener],
  data() {
    return {
      width: 0,
      height: 0,
      transitionDuration: 800,
    };
  },
  watch: {
    getVizElSelected() {
      // We force setting dimensions every time a user clicks a viz element,
      // because the onResize function doesn't seem to work when the viz is
      // resized from the outside as a result of adding / subtracting the nav buttons
      this.setDimensions();
      this.startTransitioning();
    },
    getFilterViz() {
      this.startTransitioning();
    },
    getNormalizeViz() {
      this.startTransitioning();
    },
  },
  computed: {
    isDesktop() {
      return this.$tvaMq === "desktop";
    },
    vizSwitchSel() {
      return (depth) => {
        // versi, images, normalized
        if (!this.getNormalizeViz) {
          // normalize disabled
          return this.getFilterViz;
        } else {
          if (depth - 1 === this.getNormalizeViz.index) {
            // normalized column
            return "normalized";
          } else if (depth - 1 < this.getNormalizeViz.index) {
            // before "normalized" column
            return `scaled-${this.getNormalizeViz.index}`;
          } else if (depth - 2 === this.getNormalizeViz.index) {
            // first column after "normalized"
            if (this.getFilterViz === "versi") {
              return "percentageVersi";
            } else {
              return "percentageImages";
            }
          } else if (depth - 3 === this.getNormalizeViz.index) {
            // second column after "normalized"
            if (this.getFilterViz === "versi") {
              return "percentageVersiNext";
            } else {
              return "percentageImagesNext";
            }
          } else {
            if (this.getFilterViz === "versi") {
              // third column after "normalized"
              return "percentageVersiNextNext";
            } else {
              return "percentageImagesNextNext";
            }
          }
        }
      };
    },
    vizSwitchSelRes() {
      let res = this.vizSwitchSel(
        this.getVizElSelected ? this.getVizElSelected.depth : 0
      );

      return res;
    },
    levelsAmount() {
      let diff = this.isDesktop ? 0 : 1;
      return this.getVizElSelected
        ? this.getVizElSelected.height + diff
        : this.hierarchyData.height;
    },
    elementsWidth() {
      const elementsWidth = this.isDesktop
        ? this.getElementsWidthDesktop
        : this.getElementsWidthSmallScreen;
      return elementsWidth.map((value) => value * this.width);
    },
    yMinPos() {
      return this.getVizElSelected
        ? this.getVizElSelected.data.progress[this.vizSwitchSelRes]
        : 0;
    },
    yMaxPos() {
      return this.getVizElSelected
        ? this.getVizElSelected.data.progress[this.vizSwitchSelRes] +
            this.getVizElSelected.data.weight[this.vizSwitchSelRes]
        : this.hierarchyData.data.weight[this.vizSwitchSelRes];
    },
    yMaxHeight() {
      return this.getVizElSelected
        ? this.getVizElSelected.data.weight[this.vizSwitchSelRes]
        : this.hierarchyData.data.weight[this.vizSwitchSelRes];
    },
    yScalePos() {
      return (value) => {
        let scale = d3Scale
          .scaleLinear()
          .domain([this.yMinPos, this.yMaxPos])
          .range([0, this.height]);
        return +scale(value).toFixed(2);
      };
    },
    yScaleHeight() {
      return (value) => {
        let scale = d3Scale
          .scaleLinear()
          .domain([0, this.yMaxHeight])
          .range([0, this.height]);
        return +scale(value).toFixed(2);
      };
    },
    xMin() {
      // let diff = this.isDesktop ? 0 : -1;
      let diff = 0; // Previously it was the line above, but this breaks the tablet version
      return this.getVizElSelected ? this.getVizElSelected.depth + diff : 0;
    },
    xMax() {
      return this.hierarchyData.height - 1;
    },

    ...mapGetters([
      "getVizElSelected",
      "getFilterViz",
      "getNormalizeViz",
      "getIsTransitioning",
      "getElementsWidthDesktop",
      "getElementsWidthSmallScreen",
      "hierarchyData",
      "getHoverData",
    ]),
  },
  methods: {
    xScale(elementDepth) {
      let domainMin = this.xMin === this.xMax + 1 ? this.xMin - 1 : this.xMin;

      const initialElPosition = this.elementsWidth.reduce(
        (prevWidthSum, nextWidth, i) => {
          return i < elementDepth ? prevWidthSum + nextWidth : prevWidthSum;
        },
        0
      );

      const xOffsetForSelection = this.elementsWidth.reduce(
        (prevWidthSum, nextWidth, i) => {
          return i < domainMin ? prevWidthSum + nextWidth : prevWidthSum;
        },
        0
      );

      return initialElPosition - xOffsetForSelection;
    },
    setDimensions() {
      const component = this.$refs.component;
      const factor = this.getVizElSelected
        ? getFontsize(window.innerHeight)
        : 1;

      if (component) {
        // I'm honestly not sure why the * 0.5 is necessary...
        // but without it the dimensions are *2 too small.
        this.width =
          component.getBoundingClientRect().width / (this.$baseFontSize * 0.5);
        this.height =
          component.getBoundingClientRect().height / (this.$baseFontSize * 0.5);

        if (this.isDesktop) {
          this.width =
            component.getBoundingClientRect().width /
            (this.$baseFontSize * factor);

          this.height =
            component.getBoundingClientRect().height /
            (this.$baseFontSize * factor);

          this.$emit("setTitleWidth", this.width);
        }

        // Also not sure why this is necessary, but for some reason in the production build
        // width / height of the component are too big :/
        if (this.$isProduction && !this.isDesktop) {
          this.width *= 0.89;
          this.height *= 0.89;
        }
      }
    },
    onResize() {
      this.setDimensions();
    },
    setVizSelection(el) {
      console.log("ciao");
      if (el.depth === this.hierarchyData.height) {
        this.$router.push({
          name: "article",
          query: { articleId: el.data.value[0].id },
        });
      } else {
        let query = this.$route.query;
        if (query.type && query.type == el.depth && query.id == el.data.id) {
          this.resetQuery();
        } else {
          this.setQuery(el.depth, el.data.id);
        }
      }
    },
    startTransitioning() {
      this.setIsTransitioning(true);
      setTimeout(() => {
        this.setIsTransitioning(false);
      }, this.transitionDuration);
    },
    ...mapActions(["setIsTransitioning", "setNormalizeViz"]),
  },

  mounted() {
    this.$nextTick(() => {
      this.setDimensions();
    });

    if (this.isDesktop) {
      setTimeout(() => {
        this.setDimensions();
      }, 500);
    }

    window.addEventListener("orientationchange", () => {
      this.$nextTick(() => {
        this.setDimensions();
      });
    });
  },
  directives: {
    resize,
  },
  components: {
    VizElement,
    // HoverBox,
  },
};
</script>
<style lang="scss" scoped>
$z-none: 0;
$z-a: 1;
$z-b: 5;
$z-c: 10;
$z-d: 50;
$z-e: 100;
$z-f: 200;
$z-g: 1000;
$z-h: 10000;
$white: $basic-white;
.viz-component {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  .mobile &,
  .tablet & {
    width: 100%;
    top: 0;
    position: relative;
    overflow: hidden;
  }
  .background {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: $z-none;

    transition: none;
    &.isTransitioning {
      transition: top 0.8s ease, height 0.8s ease;
    }
  }
  &__container {
    position: absolute;
    z-index: $z-a;
    top: 0;
    left: 0;
    border-left: toRem(1) solid $white;
    .mobile &,
    .tablet & {
      overflow: visible;
      transition: transform 0.5s ease;
    }
  }
}

.desktop {
  .viz-component {
    &__container {
    }
  }
}
</style>
