<template>
  <div
    class="kpi-viz"
    :style="{
      '--line-length': lineLength + 'rem',
      '--mid-point-translation-x': midPointTranslation + 'rem',
      '--data-point-translation-x': dataPointTranslation + 'rem',
    }"
  >
    <div class="kpi-viz__labels">
      <span
        v-for="(label, i) of labels"
        :key="label"
        :class="{ 'label-mid': i === 1 }"
      >
        {{ label }}
      </span>
    </div>

    <div class="kpi-viz__x-axis">
      <div class="kpi-viz__x-axis__mid-point" />
      <div class="kpi-viz__x-axis__data-point" />
    </div>


    <div class="kpi-viz__doubleKpi" v-if="totalAverage !== kpiValue.value">
      <div class="kpi-viz__doubleKpi__title">{{ kpiValue.label }}</div>
      <div class="kpi-viz__doubleKpi__box">
      <KPI v-bind="{...animatedKpiValue, ...{label: 'Selezione', bubbleColor: 'var(--primary-dark)', squareSymbol: true}}" />
      <KPI v-bind="{label: 'Costituzione', value: totalAverage, bubbleColor: 'var(--primary-light)', squareSymbol: true}" />
    </div>
    </div>
    <template v-else>
      <KPI v-bind="animatedKpiValue" />
    </template>


  </div>
</template>

<script>
import { scaleLinear } from "d3-scale";
import KPI from "./KPI.vue";
import formatNumber from "../mixins/formatNumber";
import { gsap } from "gsap";

export default {
  components: { KPI },
  mixins: [formatNumber],
  props: {
    labels: Array,
    dataMinMax: Array,
    totalAverage: Number,
    kpiValue: Object,
  },
  data() {
    return {
      lineLength: 348 / this.$baseFontSize,
      animatedKpiValue: null,
    };
  },
  created() {
    this.animatedKpiValue = this.kpiValue;
  },
  computed: {
    dataPointTranslationScale() {
      return scaleLinear().domain(this.dataMinMax).range([0, this.lineLength]);
    },
    midPointTranslation() {
      return this.dataPointTranslationScale(this.totalAverage);
    },
    dataPointTranslation() {
      if (this.kpiValue.value) {
        return this.dataPointTranslationScale(this.kpiValue.value);
      }
      return this.midPointTranslation;
    },
  },
  watch: {
    kpiValue(newValue, oldValue) {
      const olderValue = { ...oldValue };

      gsap.to(olderValue, {
        value: newValue.value,
        duration: 2,
        ease: "power1.out",
        stagger: {
          each: 0.1,
          onUpdate: () => {
            this.animatedKpiValue = olderValue;
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi-viz {
  margin-top: 1rem;

  &__labels {
    @include viz-label;

    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: var(--line-length);
    position: relative;

    // &.label-values {
    //   @include viz-label-value;

    //   margin-top: -1rem;
    // }
    .label-mid {
      display: none;
      // position: absolute;
      // left: 0;
      // transform: translateX(calc(-50% + var(--mid-point-translation-x)));
    }
  }

  &__x-axis {
    width: 100%;
    height: toRem(1);
    border-bottom: toRem(1) dashed $basic-black;
    position: relative;
    width: var(--line-length);
    margin-bottom: 2rem;

    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 1rem;
      width: toRem(1);
      background-color: $basic-black;
      transform: translateY(calc(-50% + #{toRem(1)}));
    }

    &::after {
      right: 0;
    }

    &__mid-point,
    &__data-point {
      position: absolute;
      width: toRem(20);
      height: toRem(20);
      top: 50%;
      border-radius: toRem(5);
    }

    &__mid-point {
      left: 0;
      background-color: $primary-light;
      transform: translate(calc(-50% + var(--mid-point-translation-x)), -50%)
        rotate(45deg);

      &::after {
        content: "";
        position: absolute;
        width: toRem(5);
        height: toRem(5);
        background-color: $basic-white;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__data-point {
      background-color: $primary-dark;
      transform: translate(calc(-50% + var(--data-point-translation-x)), -50%)
        rotate(45deg);
      z-index: 1;
      transition: transform 0.5s;
    }
  }
}

.desktop {
  .kpi-viz {
    margin-top: 0;
    &__labels {
      font-size: toRem(12);
      width: toRem(275);
    }

    &__x-axis {
      width: toRem(275);
      margin-bottom: toRem(10);
    }
    &__doubleKpi {
      &__box {
        display: flex;
        gap: .8rem;
      }
   
      &__title {
        width: 100%;
      }
    }
  }
}
</style>
