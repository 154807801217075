/**
 * TODO: Memoize the KPIs so that we don't have to loop arrays every time
 */
// const memoizedKPIS = {
// }

/**
 * Takes an array of numbers and returns
 * a sorted array with unique values
 *
 * @param {*} data
 * @returns
 */
function sortUniqueValues(data) {
  return [...new Set(data)].sort((a, b) => a - b);
}

/**
 * Calculates average of array of numbers
 *
 * @param {*} data
 * @returns
 */
export function getAverage(data, key) {
  const sum = data.reduce(
    (prevTotal, nextAmount) => prevTotal + (nextAmount[key] || nextAmount),
    0
  );

  return sum / data.length;
}

/**
 * Returns min, max and average value of paragraphs per article
 * for a given array of data
 *
 * @param {*} data
 * @returns
 */
export function getParagraphKPIs(data) {
  const amountsParagraphs = data.map((article) => article.amountParagraphs);
  // Get list of sorted paragraphs per article, without duplicates
  const sortedParagraphsPerArticle = sortUniqueValues(amountsParagraphs);

  const averageParagraphsPerArticle = getAverage(amountsParagraphs);

  return {
    minParagraphsPerArticle: sortedParagraphsPerArticle[0],
    maxParagraphsPerArticle:
      sortedParagraphsPerArticle[sortedParagraphsPerArticle.length - 1],
    averageParagraphsPerArticle,
  };
}

/**
 *
 * @param {*} data
 * @returns
 */
export function getWordKPIs(data) {
  const averageWordCounts = data
    .map((article) => article.averageWordsPerSentence)
    .flat();
  const sortedWordCountsPerSentence = sortUniqueValues(averageWordCounts);

  const averageWordCountPerSentence = getAverage(averageWordCounts);

  return {
    minWordCountPerSentence: sortedWordCountsPerSentence[0],
    maxWordCountPerSentence:
      sortedWordCountsPerSentence[sortedWordCountsPerSentence.length - 1],
    averageWordCountPerSentence,
  };
}

/**
 * Returns an object will all KPIs
 *
 * @param {*} data
 * @returns
 */
export function getGeneralKPIs(data) {
  return {
    ...getParagraphKPIs(data),
    ...getWordKPIs(data),
  };
}
