// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/fonts/Inter-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/fonts/Inter-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/fonts/Inter-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/fonts/Spectral-ExtraBold.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("@/assets/fonts/Spectral-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("@/assets/fonts/Spectral-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("@/assets/fonts/GT-Super-Display-Super.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
exports.push([module.id, ":root{--label-width:0.9375rem;--label-margin:0.4375rem;--basic-white:#fff;--basic-white_80:hsla(0,0%,100%,0.8);--basic-white_40:hsla(0,0%,100%,0.4);--basic-black:#2b2b2b;--basic-black_60:rgba(0,0,0,0.6);--basic-dark-grey:#656565;--basic-light-grey:#f0f0f0;--primary-light:#d2efff;--primary-lighter:#e5f6ff;--primary-dark:#63a2ee;--primary-grey:#dbdbdb;--primary-light-30:#c6d2f2;--primary-light-40:rgba(197,209,242,0.4);--tema_minoranze:#f0d259;--tema_unità:#acdd91;--tema_lavoro:#fab395;--tema_uguaglianza:#ecb1e2;--tema_minoranze-dark:#e6b000;--tema_unità-dark:#84ce5f;--tema_lavoro-dark:#f89a72;--tema_uguaglianza-dark:#e184d2;--secondary-yellow:#f0d25c;--secondary-green:#acdd92;--secondary-pink:#ecb1e2;--secondary-orange:#f9b394}@font-face{font-family:Inter;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");font-weight:800}@font-face{font-family:Inter;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:700}@font-face{font-family:Inter;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\");font-weight:400}@font-face{font-family:Spectral;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:800}@font-face{font-family:Spectral;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff\");font-weight:700}@font-face{font-family:Spectral;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-weight:400}@font-face{font-family:GT Super;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff\");font-weight:800}.fadepage-enter-active,.fadepage-leave-active{transition:opacity .5s}.fadepage-enter,.fadepage-leave-to{position:fixed;top:0;left:0;opacity:0}.fade-enter-active,.fade-leave-active{transition:opacity .5s}.fade-enter,.fade-leave-to{opacity:0}.back .constitution-card__content{-ms-writing-mode:tb-rl;writing-mode:vertical-rl;text-orientation:mixed;transform:rotate(180deg)}", ""]);
// Exports
module.exports = exports;
