<template>
  <div
    :class="[
      'section-card',
      { 'multiple-sections': sections.length > 1, 'with-text': showText },
    ]"
  >
    <div
      v-for="section of sections.slice().reverse()"
      :key="section.sectionNr"
      class="section-card__text"
      @mousemove="(e) => $emit('onCardMove', { e, section: section })"
      @mouseleave="(e) => $emit('onCardLeave', { e, section: section })"
    >
      <template v-if="showText">
        <Title :title="section.sectionNr" class="section-card__text__title" />
        {{ section.section }}
      </template>
    </div>
  </div>
</template>

<script>
import Title from "./Title.vue";

export default {
  components: { Title },
  props: {
    sections: Array,
    articleHeight: Number,
  },
  computed: {
    showText() {
      return (
        this.articleHeight / this.sections.length > 8 &&
        this.$tvaMq !== "desktop"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.section-card {
  @include flip-text;

  display: flex;
  justify-content: space-around;

  &__text {
    @include title-h-2;

    $max-text-height: 4rem;

    width: 100%;
    height: 50%;
    background-color: $primary-lighter;

    &__title {
      @include title-h-5;
    }

    .with-text & {
      padding: toRem(90) 1rem 0.5rem;
      padding-top: toRem(80);
    }

    .multiple-sections & {
      &:not(:last-child) {
        border-bottom: 0.25rem solid $basic-white;
      }
    }
  }
}

.desktop {
  .section-card {
    &__text {
      transition: background-color 0.3s ease;

      &:hover {
        background-color: var(--primary-dark);
      }
    }
  }
}
</style>
