var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.elements),function(el,elIndex){return _c('div',{key:((el.depth) + "-" + elIndex),class:("viz-section " + (_vm.vizSwitchSel(el.depth)) + " depth-" + (el.depth))},[_c(el.depth === _vm.highestDepth
          ? 'article-card-with-labels'
          : 'constitution-card',_vm._b({tag:"component",class:[
        'main-rect',
        {
          isTransitioning: _vm.isTransitioning,
          'is-disposizione': _vm.isDisposizione(el.data),
          abrogated: el.depth === _vm.highestDepth && el.data.value[0].abrogated,
          'on-first-column': _vm.cardContentIsVisible(el),
          'is-article': el.depth === _vm.highestDepth,
          'has-sections':
            _vm.cardContentIsVisible(el) &&
            el.depth === 2 &&
            !el.data.articles[0].noSection,
        } ],style:({
        top: _vm.yScalePos(el.data.progress[_vm.vizSwitchSel(el.depth)]) + 'rem',
        left: _vm.xScale(el.depth - 1) + 'rem',
        width: _vm.elementsWidth[el.depth - 1] - _vm.cardMargin + 'rem',
        height: _vm.getHeightCorrectedForCardMargin(el) + 'rem',
        '--card-translation-y': _vm.getCardMargin(el).endMarginOffset + 'rem',
        '--dog-ear-size': _vm.getDogEarSize(el) + 'rem',
        '--transition-duration': _vm.transitionDuration / 1000 + 's',
      }),attrs:{"viz":_vm.isDesktop,"withDogEar":_vm.cardContentIsVisible(el),"showArrow":_vm.getDogEarSize(el) >= 3,"aria-label":_vm.getElementText(el)},on:{"onCardMove":function (e) {
          if (
            _vm.cardContentIsVisible(el) &&
            el.depth === 2 &&
            !el.data.articles[0].noSection
          ) {
            e.preventDefault();
          } else {
            _vm.setHoverData(el);
            _vm.setHoverX(e.clientX);
            _vm.setHoverY(e.clientY);
          }
        },"onCardLeave":function (e) {
          if (
            _vm.cardContentIsVisible(el) &&
            el.depth === 2 &&
            !el.data.articles[0].noSection
          ) {
            e.preventDefault();
          } else {
            _vm.setHoverData(null);
            _vm.setHoverX(0);
            _vm.setHoverY(0);
          }
        },"onCardClick":function($event){return _vm.$emit('click', el)}}},'component',Object.assign({}, (el.depth === _vm.highestDepth ? { article: el.data.value[0] } : {}),
        (_vm.isDisposizione(el.data)
          ? {
              gradientColor: 'var(--basic-dark-grey)',
            }
          : {}),
        {disabled:
          (el.depth !== _vm.highestDepth &&
            !_vm.cardContentIsVisible(el) &&
            _vm.$tvaMq !== 'desktop') ||
          (el.depth === _vm.highestDepth &&
            !_vm.articlesAreClickable &&
            _vm.$tvaMq !== 'desktop')}),false),[_c('transition',{attrs:{"name":"fade"}},[(_vm.cardContentIsVisible(el, _vm.isDesktop) && !_vm.isTransitioning)?_c('div',{class:['text'],style:({
            top:
              _vm.yScalePos(el.data.progress[_vm.vizSwitchSel(el.depth)]) +
              _vm.yScaleHeight(el.data.weight[_vm.vizSwitchSel(el.depth)]) / 2 +
              5 +
              'rem',
            left:
              _vm.xScale(el.depth - 1) +
              (_vm.$tvaMq !== 'desktop' ? _vm.elementsWidth[el.depth - 1] / 2 : 0) +
              'rem',
          })},[(
              (el.data.namePrefix && _vm.$tvaMq !== 'desktop') ||
              (el.data.namePrefix && el.depth < 2 && _vm.isDesktop)
            )?_c('Title',{attrs:{"title":el.data.namePrefix}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getElementText(el))+" "),(
              _vm.cardContentIsVisible(el) &&
              el.depth === 2 &&
              !el.data.articles[0].noSection
            )?_c('SectionCard',{staticClass:"section",attrs:{"sections":_vm.getSectionsForArticle(el.data.articles),"articleHeight":_vm.getHeightCorrectedForCardMargin(el)},on:{"onCardMove":function (object) {
                _vm.setHoverData(object.section);
                _vm.setHoverX(object.e.clientX);
                _vm.setHoverY(object.e.clientY);
              },"onCardLeave":function (e) {
                _vm.setHoverData(null);
                _vm.setHoverX(0);
                _vm.setHoverY(0);
              }}}):_vm._e()],1):_vm._e()])],1),(el.children)?_c('viz-element-comp',{attrs:{"elements":el.children,"elementsWidth":_vm.elementsWidth,"xScale":_vm.xScale,"yScalePos":_vm.yScalePos,"yScaleHeight":_vm.yScaleHeight,"isTransitioning":_vm.isTransitioning,"transitionDuration":_vm.transitionDuration,"vizSwitchSel":_vm.vizSwitchSel,"highestDepth":_vm.highestDepth},on:{"click":function($event){return _vm.$emit('click', $event)}}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }