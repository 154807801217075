import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  created() { },
  watch: {
    $route() {
      this.checkQuery();
    },
    // isDatasetLoading (value) {
    //   if (!value) {
    //     this.checkQuery ();
    //   }
    // },
  },
  computed: {
    hierarchyLeaves() {
      return this.hierarchyData.leaves();
    },
    ...mapGetters(['hierarchyData', 'getVizElSelected']),
  },
  methods: {
    checkQuery() {
      let query = this.$route.query;
      this.queryParams = query;
      if (query && query.type) {
        this.findVizEl(parseInt(query.type), query.id);
      } else {
        this.resetViz();
      }
    },
    findVizEl(depth, id) {
      let element;
      let splitId = id.toString().split('-');
      switch (depth) {
        case 1:
          element = this.hierarchyData.children[splitId[0]];
          break;
        case 2:
          element = this.hierarchyData.children[splitId[0]].children[
            splitId[1]
          ];
          break;

        case 3:
          element = this.hierarchyData.children[splitId[0]].children[splitId[1]]
            .children[splitId[2]];
          break;

        case 4:
          element = this.hierarchyData.children[splitId[0]].children[splitId[1]]
            .children[splitId[2]].children[splitId[3]];
          break;
      }
      this.setVizElSelected(element);
      this.setPrevVizEl(this.findElement(element, -1));
      this.setNextVizEl(this.findElement(element, +1));
    },
    findPrevLeaf(el) {
      if (!this.hierarchyLeaves) {
        return null;
      } else {
        const selectionIndex = _.findIndex(
          this.hierarchyLeaves,
          o => o.data.id === el.data.id
        );
        let prevLeaf = this.hierarchyLeaves[selectionIndex - 1];
        return prevLeaf ? prevLeaf : null;
      }
    },
    findNextLeaf(el) {
      if (!this.hierarchyLeaves) {
        return null;
      } else {
        const selectionIndex = _.findIndex(
          this.hierarchyLeaves,
          o => o.data.id === el.data.id
        );
        let nextLeaf = this.hierarchyLeaves[selectionIndex + 1];

        return nextLeaf ? nextLeaf : null;
      }
    },
    findElement(el, position) {
      let depth = el.depth;
      let firstLeaf = el.leaves()[position == -1 ? 0 : el.leaves().length - 1];
      let allLeaves = this.hierarchyData.leaves();
      let firstLeafIndex = allLeaves.findIndex(o => o.data.id === firstLeaf.data.id);
      if (allLeaves[firstLeafIndex + position]) {

        let previousLeaf = allLeaves[firstLeafIndex + position];

        let previousLeafAncestors = previousLeaf.ancestors();
        let res = previousLeafAncestors.find(d => d.depth === depth);
        return res;
      } else {
        return null
      }
    },
    ...mapActions([
      'setVizElSelected',
      'setPrevVizEl',
      'setNextVizEl',
      'resetViz',
    ]),
  },
};
