var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hover-box",style:({
    '--top': ("" + (_vm.getHoverY - 21)),
    '--left': ("" + (_vm.getHoverX + 20)),
  })},[_c('span',{staticClass:"hover-box__prefix"},[_vm._v(_vm._s(!!_vm.getHoverData.sectionNr ? _vm.getHoverData.sectionNr : !!_vm.getHoverData.data && parseInt(_vm.getHoverData.data.name) ? "art." : _vm.getHoverData.data.namePrefix))]),_c('span',{class:[
      'hover-box__name',
      {
        withPrefix:
          (!!_vm.getHoverData.data &&
            (!!_vm.getHoverData.data.namePrefix ||
              parseInt(_vm.getHoverData.data.name))) ||
          _vm.getHoverData.section,
      } ]},[_vm._v(_vm._s(!!_vm.getHoverData.section ? _vm.getHoverData.section : parseInt(_vm.getHoverData.data.name) ? parseInt(_vm.getHoverData.data.name) : _vm.getHoverData.data.name))])])}
var staticRenderFns = []

export { render, staticRenderFns }