<template>
  <div>
    <div
      v-for="(el, elIndex) in elements"
      :key="`${el.depth}-${elIndex}`"
      :class="`viz-section ${vizSwitchSel(el.depth)} depth-${el.depth}`"
    >
      <component
        :is="
          el.depth === highestDepth
            ? 'article-card-with-labels'
            : 'constitution-card'
        "
        :class="[
          'main-rect',
          {
            isTransitioning: isTransitioning,
            'is-disposizione': isDisposizione(el.data),
            abrogated: el.depth === highestDepth && el.data.value[0].abrogated,
            'on-first-column': cardContentIsVisible(el),
            'is-article': el.depth === highestDepth,
            'has-sections':
              cardContentIsVisible(el) &&
              el.depth === 2 &&
              !el.data.articles[0].noSection,
          },
        ]"
        :viz="isDesktop"
        :withDogEar="cardContentIsVisible(el)"
        :showArrow="getDogEarSize(el) >= 3"
        :aria-label="getElementText(el)"
        v-bind="{
          ...(el.depth === highestDepth ? { article: el.data.value[0] } : {}),
          ...(isDisposizione(el.data)
            ? {
                gradientColor: 'var(--basic-dark-grey)',
              }
            : {}),
          disabled:
            (el.depth !== highestDepth &&
              !cardContentIsVisible(el) &&
              $tvaMq !== 'desktop') ||
            (el.depth === highestDepth &&
              !articlesAreClickable &&
              $tvaMq !== 'desktop'),
        }"
        v-bind:style="{
          top: yScalePos(el.data.progress[vizSwitchSel(el.depth)]) + 'rem',
          left: xScale(el.depth - 1) + 'rem',
          width: elementsWidth[el.depth - 1] - cardMargin + 'rem',
          height: getHeightCorrectedForCardMargin(el) + 'rem',
          '--card-translation-y': getCardMargin(el).endMarginOffset + 'rem',
          '--dog-ear-size': getDogEarSize(el) + 'rem',
          '--transition-duration': transitionDuration / 1000 + 's',
        }"
        @onCardMove="
          (e) => {
            if (
              cardContentIsVisible(el) &&
              el.depth === 2 &&
              !el.data.articles[0].noSection
            ) {
              e.preventDefault();
            } else {
              setHoverData(el);
              setHoverX(e.clientX);
              setHoverY(e.clientY);
            }
          }
        "
        @onCardLeave="
          (e) => {
            if (
              cardContentIsVisible(el) &&
              el.depth === 2 &&
              !el.data.articles[0].noSection
            ) {
              e.preventDefault();
            } else {
              setHoverData(null);
              setHoverX(0);
              setHoverY(0);
            }
          }
        "
        @onCardClick="$emit('click', el)"
      >
        <transition name="fade">
          <div
            v-if="cardContentIsVisible(el, isDesktop) && !isTransitioning"
            :class="['text']"
            :style="{
              top:
                yScalePos(el.data.progress[vizSwitchSel(el.depth)]) +
                yScaleHeight(el.data.weight[vizSwitchSel(el.depth)]) / 2 +
                5 +
                'rem',
              left:
                xScale(el.depth - 1) +
                ($tvaMq !== 'desktop' ? elementsWidth[el.depth - 1] / 2 : 0) +
                'rem',
            }"
          >
            <Title
              v-if="
                (el.data.namePrefix && $tvaMq !== 'desktop') ||
                (el.data.namePrefix && el.depth < 2 && isDesktop)
              "
              :title="el.data.namePrefix"
            />
            {{ getElementText(el) }}

            <SectionCard
              v-if="
                cardContentIsVisible(el) &&
                el.depth === 2 &&
                !el.data.articles[0].noSection
              "
              @onCardMove="
                (object) => {
                  setHoverData(object.section);
                  setHoverX(object.e.clientX);
                  setHoverY(object.e.clientY);
                }
              "
              @onCardLeave="
                (e) => {
                  setHoverData(null);
                  setHoverX(0);
                  setHoverY(0);
                }
              "
              class="section"
              :sections="getSectionsForArticle(el.data.articles)"
              :articleHeight="getHeightCorrectedForCardMargin(el)"
            />
          </div>
        </transition>
      </component>
      <viz-element-comp
        v-if="el.children"
        :elements="el.children"
        :elementsWidth="elementsWidth"
        :xScale="xScale"
        :yScalePos="yScalePos"
        :yScaleHeight="yScaleHeight"
        :isTransitioning="isTransitioning"
        :transitionDuration="transitionDuration"
        :vizSwitchSel="vizSwitchSel"
        :highestDepth="highestDepth"
        @click="$emit('click', $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ConstitutionCard from "../ConstitutionCard.vue";
import ArticleCardWithLabels from "../ArticleCardWithLabels.vue";
import Title from "../Title.vue";
import SectionCard from "../SectionCard.vue";
import { formatArticleNumber } from "../../utilities/functions/formatters";
import uniqBy from "lodash/uniqBy";

export default {
  name: "viz-element-comp",
  components: {
    ConstitutionCard,
    ArticleCardWithLabels,
    Title,
    SectionCard,
  },
  props: [
    "elements",
    "elementsWidth",
    "xScale",
    "yScalePos",
    "yScaleHeight",
    "isTransitioning",
    "transitionDuration",
    "vizSwitchSel",
    "highestDepth",
  ],
  data() {
    return {
      currentDepth: null,
      // cardMargin: this.isDesktop ? 0.24 : 0.94, // Value is in rem
    };
  },
  methods: {
    cardContentIsVisible(element, secondDepth = false) {
      // In case of article card, show content if article is clickable
      // and if the text fits inside the article
      if (element.depth === this.highestDepth) {
        return (
          this.articlesAreClickable &&
          this.getHeightCorrectedForCardMargin(element) >= 1.5
        );
      }

      // Cases for non-article cards, only show content when the card is
      // at the rightmost level
      if (this.getVizElSelected) {
        return this.getVizElSelected.depth + 1 >= element.depth;
      } else {
        return (
          this.getHeightCorrectedForCardMargin(element) >= 1.5 &&
          (element.depth === 1 || (secondDepth && element.depth === 2))
        );
      }
    },
    prova() {
      console.log("ciao");
    },
    isAbrogated(elData) {
      return elData.value && elData.value[0].abrogated;
    },
    isDisposizione(elData) {
      return (
        (elData.articles && elData.articles[0].isDisposizione) ||
        (elData.value && elData.value[0].isDisposizione)
      );
    },
    getHeightCorrectedForCardMargin(el) {
      let marginOffset = this.getCardMargin(el).margin;

      return +(
        this.yScaleHeight(el.data.weight[this.vizSwitchSel(el.depth)]) -
        marginOffset
      ).toFixed(2);
    },
    getDogEarSize(el) {
      return (
        this.getHeightCorrectedForCardMargin(el) -
        (this.isAbrogated(el.data) ? 0.3 : 0.1)
      );
    },
    getCardMargin(el) {
      let margin = 0;
      let endMargin = 0;
      let elIndex = 0;

      // Element with depth of 1 should have default margin
      if (el.depth === 1 || el.depth === this.getVizElSelected?.depth + 1) {
        margin = this.cardMargin;

        // Last element has margin according to elements with depth 1
      } else if (el.depth === this.highestDepth) {
        // Find the index according to all articles belong to the depth 1 parent
        elIndex = el.parent.parent.data.articles.findIndex(
          (article) => article.id === el.data.value[0].id
        );

        margin = this.cardMargin / el.parent.parent.data.articles.length;
        endMargin =
          (this.cardMargin - margin) / el.parent.parent.data.articles.length;

        // Other elements should have depth based on the amount of siblings they have
      } else {
        elIndex = this.getElementIndex(el);
        margin = +(this.cardMargin / el.parent.children.length).toFixed(2);
        endMargin = +(
          (this.cardMargin - margin) /
          el.parent.children.length
        ).toFixed(2);
      }

      // End margin offset is to make sure that the whole group
      // that belongs to level 1 datapoint has toRem(15) margin bottom
      return {
        endMarginOffset: endMargin * -1 * elIndex,
        margin: margin + endMargin,
      };
    },
    getElementIndex(el) {
      const idParts = el.data.id.split("-");
      return +idParts[idParts.length - 1];
    },
    getElementText(element) {
      const textPrefix = this.isDisposizione(element.data) ? "" : "Art. ";

      return element.depth === this.highestDepth
        ? textPrefix + formatArticleNumber(element.data.name)
        : element.data.name;
    },
    getSectionsForArticle(articles) {
      return uniqBy(
        articles.map((article) => ({
          section: article.section,
          sectionNr: article.sectionNr,
        })),
        "sectionNr"
      );
    },
    ...mapActions(["setHoverData", "setHoverX", "setHoverY"]),
  },
  computed: {
    isDesktop() {
      return this.$tvaMq === "desktop";
    },
    cardMargin() {
      return this.isDesktop ? 0.34 : 0.94;
    },
    articlesAreClickable() {
      return this.getVizElSelected || !!this.selectedLabel;
    },
    ...mapGetters([
      "getVizElSelected",
      "getNormalizeViz",
      "getFilterViz",
      "selectedLabel",
      "getHoverData",
    ]),
  },
};
</script>

<style lang="scss" scoped>
g {
  pointer-events: none;
}

.main-rect {
  --card-border: var(--basic-white);

  text-align: left;
  border: none;
  position: absolute;
  transform: translateY(var(--card-translation-y));
  transition: all var(--transition-duration) ease, background-color 0.3s ease,
    color 0s ease;

  &.abrogated {
    ::v-deep .constitution-card {
      background:#fff;
      border: solid toRem(2) $primary-light !important;
    }
  }

  &.on-first-column {
    border: solid toRem(1) $basic-dark-grey;

    &.is-article {
      border: none;
      ::v-deep .constitution-card {
        border: solid toRem(1) $basic-dark-grey;
      }

      // &.abrogated {
      //   --card-color: var($primary-light);
      //   // ::v-deep .constitution-card {
      //   //   border: solid toRem(4) $primary-light;
      //   // }
      // }
    }

    .section {
      position: absolute;
      width: 25%;
      top: 0;
      right: 0;
      height: 100%;
    }
  }

  &.is-disposizione {
    --card-color: var(--primary-grey);
  }
}
text {
  position: absolute;
  // @include ButtonS;
  fill: $basic-white;
  transform: translateX(toRem(10));
  animation: fade 0.3s ease;
  transition: opacity 0.3s ease;
  opacity: 0.6;
  font-size: toRem(13);

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.6;
    }
  }

  &.selected {
    opacity: 1;
  }
  .mobile &,
  .tablet & {
    opacity: 1;
    text-anchor: middle;
    transform: translateX(0);
  }
}

.desktop {
  .main-rect {
    &:not(.is-article):not(.has-sections):hover {
      background-color: var(--primary-dark);
      color: var(--basic-white) !important;
    }

    &.is-article {
      .text {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 0.75rem;
      }

      &:hover {
        &::v-deep .article-card-with-labels__card {
          background-color: var(--primary-dark);
        }
      }
    }
  }
  .text {
    font-size: 0.875rem;
    line-height: 120%;

    & > .section {
    }
  }
}
</style>
