<template>
  <div
    class="article-card-with-labels"
    :style="{ '--amount-labels': totalAmountLabels }"
    @mousemove="
      (e) => {
        if (viz) return $emit('onCardMove', e);
      }
    "
    @mouseleave="
      (e) => {
        if (viz) return $emit('onCardLeave', e);
      }
    "
  >
    <ConstitutionCard
      class="article-card-with-labels__card"
      :withDogEar="withDogEar"
      @onCardClick="$emit('onCardClick')"
      :disabled="disabled"
      :gradientColor="gradientColor"
      :showArrow="showArrow"
      :viz="$tvaMq === 'desktop'"
      :aria-label="ariaLabel"
    >
      <slot />
    </ConstitutionCard>

    <div class="article-card-with-labels__labels">
      <div
        class="article-card-with-labels__labels__label"
        v-for="label of article.labels"
        :key="label"
        :style="{ '--label-color': `var(--${label})` }"
      />
    </div>
  </div>
</template>

<script>
import ConstitutionCard from "./ConstitutionCard.vue";

export default {
  components: { ConstitutionCard },
  props: {
    article: Object,
    withDogEar: Boolean,
    disabled: Boolean,
    gradientColor: {
      type: String,
      default: "var(--primary-dark)",
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    viz: {
      type: Boolean,
      default: false,
    },
    ariaLabel: String
  },
  computed: {
    totalAmountLabels() {
      return Object.keys(this.$t("themes")).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.article-card-with-labels {
  display: flex;
  pointer-events: none;
  &__card {
    transition: background-color 0.3s ease;
    width: calc(
      100% - (var(--label-margin) + var(--label-width)) * var(--amount-labels)
    );
    height: 100%;
  }

  &__labels {
    display: flex;
    margin-left: toRem(15);

    &__label {
      height: 100%;
      width: var(--label-width);
      background-color: var(--label-color);
      border-radius: toRem(10);
      margin-right: var(--label-margin);
    }
  }
}
</style>
