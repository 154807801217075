export function getFontsize(windowHeight) {
  if (windowHeight >= 750) {
    return 1;
  }

  if (windowHeight >= 700) {
    return 0.95;
  }

  if (windowHeight >= 670) {
    return 0.9;
  }

  if (windowHeight >= 600) {
    return 0.85;
  }
}
