var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"constitution-page"},[_c('ConstitutionHeader'),_c('div',{staticClass:"constitution-page__content",style:({
      '--back-btn-width':
        _vm.getVizElSelected && _vm.getVizElSelected.parent ? '7.25rem' : '0rem',
    })},[_c('section',{staticClass:"constitution-page__content__kpis"},[_c('ConstitutionKPIs',{attrs:{"content":_vm.content.constitutionKPIs}})],1),_c('section',{staticClass:"constitution-page__content__viz"},[_c('div',{class:[
          'constitution-page__content__viz__titles',
          { 'no-selection': !_vm.getVizElSelected } ],style:(_vm.titlesWidth
            ? {
                width: _vm.titlesWidth + 'rem',
              }
            : {})},_vm._l((_vm.currentVizTitles),function(title,i){return _c('span',{key:title,staticClass:"constitution-page__content__viz__titles__title",style:({
            '--title-translation-x': _vm.getTitleTranslation(i),
            '--amount-labels': _vm.totalAmountLabels,
          })},[_vm._v(" "+_vm._s(title)+" ")])}),0),(_vm.getPrevVizElement)?_c('VizNavigationButton',{staticClass:"nav--top",attrs:{"element":_vm.getPrevVizElement}}):_vm._e(),_c('div',{class:[
          'constitution-page__content__viz__viz-container',
          {
            'resize-1': _vm.getPrevVizElement || _vm.getNextVizElement,
            'resize-2': _vm.getPrevVizElement && _vm.getNextVizElement,
          } ]},[(_vm.getVizElSelected && _vm.getVizElSelected.parent)?_c('VizNavigationButton',{attrs:{"element":_vm.getVizElSelected,"type":"back"}}):_vm._e(),_c('VizComponent',{staticClass:"constitution-page__content__viz__viz-container__viz-el",on:{"setTitleWidth":_vm.setTitleWidthFromViz}})],1),(_vm.getNextVizElement)?_c('VizNavigationButton',{attrs:{"element":_vm.getNextVizElement}}):_vm._e()],1)]),_c('Transition',{attrs:{"name":"fade"}},[(_vm.getModalState)?_c('ModalSocialShare'):_vm._e()],1),_c('Transition',{attrs:{"name":"fade"}},[(!!_vm.getHoverData)?_c('HoverBox'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }