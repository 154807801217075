<template>
  <div class="theme-filter">
    <ThemeButton
      v-for="(_, theme) of themes"
      class="theme-filter__btn"
      :key="theme"
      :theme="theme"
      :isActive="getLabelActive(theme)"
      :isInactive="
        (selectedLabel && !getLabelActive(theme)) ||
        (!!hoveredLabel && hoveredLabel !== theme && !getLabelActive(theme))
      "
      @onThemeSelect="resetAndFilterViz($event)"
      @mouseenter="toggleHover(theme)"
      @mouseleave="toggleHover('')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { enrichDataFunc } from "@/utilities/functions/enrichData";
import ThemeButton from "./ThemeButton.vue";
import genericQueryMethods from "../mixins/genericQueryMethods";

export default {
  components: { ThemeButton },
  mixins: [genericQueryMethods],
  data() {
    return {
      hoveredLabel: "",
    };
  },
  computed: {
    themes() {
      return this.$t("themes");
    },
    ...mapGetters(["filteredData", "selectedLabel"]),
  },
  methods: {
    getLabelActive(theme) {
      return this.selectedLabel === theme;
    },
    resetAndFilterViz(theme) {
      this.resetQuery();
      this.setSelectedLabel(theme);
    },
    toggleHover(theme) {
      console.log(theme);
      this.hoveredLabel = theme;
      console.log("hoveredLabel", this.hoveredLabel);
    },
    ...mapActions(["setSelectedLabel", "setHierarchyData"]),
  },
  watch: {
    selectedLabel() {
      this.setHierarchyData(enrichDataFunc(this.filteredData).enrichedData);
    },
  },
};
</script>

<style lang="scss" scoped>
.theme-filter {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  width: 100%;

  &__btn {
    margin-right: 1rem;

    &:not(:last-child) {
      margin-bottom: toRem(15);
    }
  }
}

.desktop {
  .theme-filter {
    &__btn {
      &:not(:last-child) {
        margin-bottom: toRem(5);
      }
    }
  }
}
</style>
