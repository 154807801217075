<template>
  <ConstitutionCard
    :class="[
      'viz-nav-btn',
      type,
      { 'is-disposizione': isDisposizione(element.data) },
    ]"
    :viz="$tvaMq === 'desktop'"
    :navButton="$tvaMq === 'desktop'"
    :style="{
      '--amount-labels': totalAmountLabels,
      '--card-border': isDisposizione(element.data)
        ? 'var(--basic-dark-grey)'
        : 'var(--primary-dark)',
    }"
    v-bind="{
      ...(isDisposizione(element.data)
        ? {
            gradientColor: 'var(--basic-dark-grey)',
          }
        : {}),
    }"
    :vertical="type === 'back'"
    @onCardClick="handleClick(element)"
  >
    <div class="viz-nav-btn__content">
      <Title
        v-if="element.data.namePrefix"
        class="viz-nav-btn__content__title"
        :title="element.data.namePrefix"
      />
      {{ element.data.name }}
    </div>
  </ConstitutionCard>
</template>

<script>
import genericQueryMethods from "@/mixins/genericQueryMethods";
import ConstitutionCard from "../ConstitutionCard.vue";
import Title from "../Title.vue";

export default {
  components: {
    ConstitutionCard,
    Title,
  },
  mixins: [genericQueryMethods],
  props: {
    element: {
      required: true,
      type: Object,
    },
    type: {
      required: false,
      default: "",
      type: String,
    },
  },
  computed: {
    totalAmountLabels() {
      return Object.keys(this.$t("themes")).length;
    },
  },
  methods: {
    handleClick(element) {
      if (this.type !== "back") {
        this.setQuery(element.depth, element.data.id);
      } else {
        if (!element.parent.parent) {
          // if it is not the root element
          this.resetQuery();
        } else {
          let newEl = element.parent;
          this.setQuery(newEl.depth, newEl.data.id);
        }
      }
    },
    isDisposizione(elData) {
      return (
        (elData.articles && elData.articles[0].isDisposizione) ||
        (elData.value && elData.value[0].isDisposizione)
      );
    },
  },
};
</script>

<style lang="scss">
.viz-nav-btn {
  // These values (15, 7) come from the size of the colors labels next to the article cards
  // TODO: 32 comes from all the margins right (should make this value correct for all viz views)
  width: calc(
    100% - (#{toRem(15)} + #{toRem(7)}) * var(--amount-labels) - toRem(32)
  );

  &:last-child {
    // --dog-ear-rotation: 45deg;
    --dog-ear-translation: translate(0.1rem, 0);
    --dog-ear-icon-rotation: 90deg;
  }

  &__content {
    display: flex;

    ::first-letter {
      text-transform: capitalize;
    }

    &__title {
      margin-right: 0.5rem;
    }
  }

  &:not(.back) {
    --dog-ear-size: #{toRem(50)};

    height: toRem(50);
    flex-direction: row-reverse;
    justify-content: flex-end;

    .viz-nav-btn__content {
      align-items: center;
    }
  }

  &.back {
    width: toRem(106);
    height: calc(100% - #{toRem(15)});
    margin-right: toRem(15);
    align-items: flex-start;

    .viz-nav-btn__content {
      flex-direction: column;
    }
  }

  &.is-disposizione {
    --card-color: var(--primary-grey);
  }
}

.desktop {
  .viz-nav-btn {
    width: calc(
      100% - (#{toRem(15)} + #{toRem(7)}) * var(--amount-labels) - toRem(22)
    );

    &.back {
      width: toRem(106);
      margin-right: toRem(5);
      height: calc(100% - #{toRem(5)});
    }

    &__content {
      font-size: 1.125rem;
      padding-left: toRem(40);
    }
  }
}
</style>

<style lang="scss">
.back {
  .constitution-card__content {
    @include flip-text;
  }
}
</style>
