<template>
  <main class="constitution-page">
    <ConstitutionHeader />

    <div
      class="constitution-page__content"
      :style="{
        '--back-btn-width':
          getVizElSelected && getVizElSelected.parent ? '7.25rem' : '0rem',
      }"
    >
      <section class="constitution-page__content__kpis">
        <ConstitutionKPIs :content="content.constitutionKPIs" />
      </section>
      <section class="constitution-page__content__viz">
        <div
          :class="[
            'constitution-page__content__viz__titles',
            { 'no-selection': !getVizElSelected },
          ]"
          :style="
            titlesWidth
              ? {
                  width: titlesWidth + 'rem',
                }
              : {}
          "
        >
          <span
            v-for="(title, i) of currentVizTitles"
            :key="title"
            class="constitution-page__content__viz__titles__title"
            :style="{
              '--title-translation-x': getTitleTranslation(i),
              '--amount-labels': totalAmountLabels,
            }"
          >
            {{ title }}
          </span>
        </div>

        <VizNavigationButton
          v-if="getPrevVizElement"
          class="nav--top"
          :element="getPrevVizElement"
        />

        <div
          :class="[
            'constitution-page__content__viz__viz-container',
            {
              'resize-1': getPrevVizElement || getNextVizElement,
              'resize-2': getPrevVizElement && getNextVizElement,
            },
          ]"
        >
          <VizNavigationButton
            v-if="getVizElSelected && getVizElSelected.parent"
            :element="getVizElSelected"
            type="back"
          />

          <VizComponent
            class="constitution-page__content__viz__viz-container__viz-el"
            @setTitleWidth="setTitleWidthFromViz"
          />
        </div>

        <VizNavigationButton
          v-if="getNextVizElement"
          :element="getNextVizElement"
        />
      </section>
    </div>
    <Transition name="fade">
      <ModalSocialShare v-if="getModalState" />
    </Transition>
    <Transition name="fade">
      <HoverBox v-if="!!getHoverData" />
    </Transition>
  </main>
</template>

<script>
import ConstitutionHeader from "../components/ConstitutionHeader.vue";
import ConstitutionKPIs from "../components/ConstitutionKPIs.vue";
import ModalSocialShare from "../components/ModalSocialShare.vue";
import VizComponent from "@/components/treemap-viz/VizComponent.vue";
import VizNavigationButton from "@/components/treemap-viz/VizNavigationButton.vue";
import HoverBox from "../components/HoverBox.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ConstitutionKPIs,
    ConstitutionHeader,
    VizComponent,
    VizNavigationButton,
    ModalSocialShare,
    HoverBox,
  },
  data() {
    return {
      titlesWidth: null,
    };
  },

  computed: {
    content() {
      return this.$t("constitutionPage");
    },
    currentDepth() {
      return this.getVizElSelected ? this.getVizElSelected.depth : 0;
    },
    currentVizTitles() {
      return this.content.viz.vizTitles.slice(this.currentDepth);
    },
    titleWidthsWithLabelSpace() {
      const labelSpace = 0.07;
      const elementsWidth =
        this.$tvaMq == "desktop"
          ? this.getElementsWidthDesktop
          : this.getElementsWidthSmallScreen;

      const widthsWithLabelSpace = [
        ...elementsWidth.slice(this.currentDepth),
        labelSpace,
      ];
      widthsWithLabelSpace[widthsWithLabelSpace.length - 2] =
        widthsWithLabelSpace[widthsWithLabelSpace.length - 2] - labelSpace;

      return widthsWithLabelSpace;
    },
    totalAmountLabels() {
      return Object.keys(this.$t("themes")).length;
    },
    ...mapGetters([
      "getPrevVizElement",
      "getNextVizElement",
      "getVizElSelected",
      "getElementsWidthDesktop",
      "getElementsWidthSmallScreen",
      "getModalState",
      "getHoverData",
    ]),
  },
  methods: {
    getTitleTranslation(titleIndex) {
      return this.titleWidthsWithLabelSpace.reduce((prevSum, nextValue, i) => {
        return titleIndex > i ? prevSum + nextValue : prevSum;
      }, 0);
    },
    setTitleWidthFromViz(width) {
      if (!this.titlesWidth) this.titlesWidth = width;
    },
    ...mapActions(["setSelectedLabel", "resetData", "resetViz"]),
  },

  beforeRouteLeave(to, from, next) {
    if (to.name !== "article") {
      this.setSelectedLabel(null);
      this.resetData();
      this.resetViz();
    }

    next();
  },
};
</script>

<style lang="scss" scoped>
.constitution-page {
  color: $basic-black;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;

  &__content {
    $content-padding-h: toRem(100);
    $content-width: calc(
      100vw - #{$content-padding-h} * 2 - var(--back-btn-width)
    );

    height: calc(var(--vh, 1vh) * 100 - #{$page-header});
    padding: 0 $content-padding-h toRem(30);
    display: flex;
    flex-direction: column;

    &__kpis {
      padding-bottom: toRem(41);
    }

    &__viz {
      width: 100%;
      height: 100%;

      &__titles {
        @include title-h-4;

        position: relative;
        font-family: "Inter";
        margin-bottom: toRem(25);
        text-transform: uppercase;
        width: 100%;
        height: 1.5rem;
        transform: translateX(var(--back-btn-width));
        transition: transform 0.5s;

        &__title {
          position: absolute;
          left: 0;
          transform: translateX(
            calc(var(--title-translation-x) * #{$content-width})
          );
          transition: transform 0.5s;
        }
      }

      .nav--top {
        --dog-ear-rotation: 225deg;
        --dog-ear-icon-rotation: -90deg;
        --dog-ear-translation: translate(.2rem, -1.2rem);

        margin-bottom: toRem(15);
      }

      &__viz-container {
        // position: relative;
        $title-total-height: toRem(64);
        $nav-btn-height: toRem(65);
        $container-height: calc(100% - #{$title-total-height});

        display: flex;
        height: $container-height;

        &.resize-1 {
          height: calc(#{$container-height} - #{$nav-btn-height});
        }

        &.resize-2 {
          height: calc(#{$container-height} - #{$nav-btn-height} * 2);
        }
      }
    }
  }
}

.desktop {
  .constitution-page {
    flex-direction: row;
    display: inline-block;
    position: relative;

    &__content {
      flex-direction: row;
      padding-left: toRem(40);
      padding-right: toRem(0);
      padding-bottom: 0;
      height: calc(var(--vh, 1vh) * 100 - toRem(50));
      overflow: hidden;

      &__kpis {
        max-height: 80vh;
      }

      &__viz {
        padding-left: toRem(17);
        width: calc(100vw - toRem(410));
        position: relative;
        height: calc(var(--vh, 1vh) * 100 - toRem(50));
        .nav--top {
          margin-bottom: toRem(5);
        }

        &__titles {
          font-size: 0.875rem;
          margin-bottom: toRem(15);
          margin-top: toRem(21);
          transform: translateX(0);
          padding-left: var(--back-btn-width);

          &.no-selection {
            --fontsize-factor: 1;
          }

          &__title {
            left: calc(
              var(--back-btn-width) + var(--title-translation-x) *
                (100% - var(--back-btn-width)) / var(--fontsize-factor, 1)
            );
            transform: initial;
            transition: left 0.5s;

            &:last-child {
              --title-translation-x: 1 !important;
              transform: translateX(
                calc(
                  var(--label-width) * var(--amount-labels) * -1 -
                    var(--label-margin) * 2
                )
              );
            }
          }
        }
      }
    }
  }
}
</style>
