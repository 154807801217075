<template>
  <div
    class="hover-box"
    :style="{
      '--top': `${getHoverY - 21}`,
      '--left': `${getHoverX + 20}`,
    }"
  >
    <span class="hover-box__prefix">{{
      !!getHoverData.sectionNr
        ? getHoverData.sectionNr
        : !!getHoverData.data && parseInt(getHoverData.data.name)
        ? "art."
        : getHoverData.data.namePrefix
    }}</span>
    <span
      :class="[
        'hover-box__name',
        {
          withPrefix:
            (!!getHoverData.data &&
              (!!getHoverData.data.namePrefix ||
                parseInt(getHoverData.data.name))) ||
            getHoverData.section,
        },
      ]"
      >{{
        !!getHoverData.section
          ? getHoverData.section
          : parseInt(getHoverData.data.name)
          ? parseInt(getHoverData.data.name)
          : getHoverData.data.name
      }}</span
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HoverBox",
  props: [],
  computed: { ...mapGetters(["getHoverData", "getHoverX", "getHoverY"]) },
};
</script>

<style lang="scss" scoped>
.hover-box {
  position: absolute;
  top: toRem(var(--top));
  left: toRem(var(--left));
  border-radius: toRem(10);
  border: 1px solid var(--primary-dark);
  z-index: 10;
  pointer-events: none;
  background-color: var(--basic-white);
  padding: toRem(10);
  height: toRem(42);

  &__prefix {
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 16.8px */
    letter-spacing: 0.84px;
    text-transform: uppercase;
  }

  &__name {
    font-family: Spectral;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.18px;

    &.withPrefix {
      margin-left: toRem(10);
    }
  }

  @media only screen and (min-height: 600px) {
    top: calc((var(--top)) / calc(16 / 100 * 85) * 1rem);
    left: calc((var(--left)) / calc(16 / 100 * 85) * 1rem);
  }

  @media only screen and (min-height: 670px) {
    top: calc((var(--top)) / calc(16 / 100 * 90) * 1rem);
    left: calc((var(--left)) / calc(16 / 100 * 90) * 1rem);
  }

  @media only screen and (min-height: 700px) {
    top: calc((var(--top)) / calc(16 / 100 * 95) * 1rem);
    left: calc((var(--left)) / calc(16 / 100 * 95) * 1rem);
  }

  @media only screen and (min-height: 750px) {
    top: calc((var(--top)) / 16 * 1rem);
    left: calc((var(--left)) / 16 * 1rem);
  }
}
</style>
