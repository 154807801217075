<template>
  <dl class="kpi">
    <div
      v-if="bubbleColor"
      :class="['kpi__bubble', { square: squareSymbol }]"
      :style="{
        '--bubble-color': bubbleColor,
        '--bubble-border-color': bubbleBorderColor || bubbleColor,
      }"
    />
    <dt class="kpi__label">{{ label }}</dt>
    <dd class="kpi__value">{{ formattedValue }}</dd>
  </dl>
</template>

<script>
import formatNumber from "../mixins/formatNumber";

export default {
  mixins: [formatNumber],
  props: {
    label: String,
    value: Number,
    bubbleColor: String,
    bubbleBorderColor: String,
    squareSymbol: { type: Boolean, default: false },
  },
  computed: {
    formattedValue() {
      return this.localeAndRound(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi {
  display: flex;
  align-items: baseline;

  &__bubble {
    $bubble-size: toRem(21);

    width: $bubble-size;
    height: $bubble-size;
    background-color: var(--bubble-color);
    border: toRem(4) solid var(--bubble-border-color);
    border-radius: 50%;
    align-self: center;
    margin-right: 0.5rem;
    order: 1;
    flex-shrink: 0;
    
  }

  &__value {
    @include kpi-l;

    margin-right: 0.5rem;
    font-style: italic;
    order: 2;
  }

  &__label {
    @include dida-m;
    order: 3;
  }
}

.desktop {
  .kpi {
    &__bubble {
      $bubble-size: toRem(14);
      width: $bubble-size;
      height: $bubble-size;

      &.square {
        $bubble-size: toRem(10);
        width: $bubble-size;
      height: $bubble-size;
      border-radius: 0.2rem;
      transform: rotate(45deg);
    }
    }

    &__value {
      @include title-h-4;
      font-style: italic;
      font-weight: 800;
      font-family: Spectral;
      order: 2;
    }

    &__label {
      font-size: 1rem;
      white-space: nowrap;
      order: 3;
    }
  }
}
</style>
