<template>
  <div :class="['kpi-section', borderSide]">
    <Title v-if="title" class="kpi-section__title" :title="title" />

    <div class="kpi-section__content">
      <slot />
    </div>
  </div>
</template>

<script>
import Title from "./Title.vue";

export default {
  components: { Title },
  props: {
    title: String,
    borderSide: String,
  },
};
</script>

<style lang="scss" scoped>
.kpi-section {
  padding: toRem(18) 0;
  padding-right: 3rem;
  border-bottom: toRem(2) solid $primary-light;

  &.right {
    border-right: toRem(2) solid $primary-light;
  }

  &__title {
    margin: toRem(14) 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    max-height: 100%;
  }
}

.desktop {
  .kpi-section {
    &.right {
      padding-right: toRem(13);
      border-right: toRem(2) solid var(--primary-light-40);
    }

    &__title {
      font-size: 0.875rem;
      color: var(--basic-dark-grey);
    }
    &__content {
      flex-direction: column;
    }
  }
}
</style>
