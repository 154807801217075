var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"component",staticClass:"viz-component",class:[_vm.$tvaMq]},[_c('div',{class:['background', { isTransitioning: _vm.getIsTransitioning }],style:({
      top:
        (_vm.getVizElSelected && _vm.getVizElSelected.data.progress
          ? _vm.yScalePos(0)
          : 0) + 'rem',
      height:
        _vm.yScaleHeight(_vm.hierarchyData.data.weight[_vm.vizSwitchSelRes]) + 'rem',
      transitionDuration: _vm.transitionDuration / 1000 + 's',
    })}),_c('div',{staticClass:"viz-component__container",style:({
      width: (_vm.width + "rem"),
      height: (_vm.height + "rem"),
      transform: ("translate3d(" + (0) + "rem,0,0)"),
    })},[_c('VizElement',{attrs:{"elements":_vm.hierarchyData.children,"elementsWidth":_vm.elementsWidth,"xScale":_vm.xScale,"yScalePos":_vm.yScalePos,"yScaleHeight":_vm.yScaleHeight,"isTransitioning":_vm.getIsTransitioning,"transitionDuration":_vm.transitionDuration,"vizSwitchSel":_vm.vizSwitchSel,"highestDepth":_vm.hierarchyData.height},on:{"click":function($event){return _vm.setVizSelection($event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }