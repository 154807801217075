var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ConstitutionCard',_vm._b({class:[
    'viz-nav-btn',
    _vm.type,
    { 'is-disposizione': _vm.isDisposizione(_vm.element.data) } ],style:({
    '--amount-labels': _vm.totalAmountLabels,
    '--card-border': _vm.isDisposizione(_vm.element.data)
      ? 'var(--basic-dark-grey)'
      : 'var(--primary-dark)',
  }),attrs:{"viz":_vm.$tvaMq === 'desktop',"navButton":_vm.$tvaMq === 'desktop',"vertical":_vm.type === 'back'},on:{"onCardClick":function($event){return _vm.handleClick(_vm.element)}}},'ConstitutionCard',Object.assign({}, (_vm.isDisposizione(_vm.element.data)
      ? {
          gradientColor: 'var(--basic-dark-grey)',
        }
      : {})),false),[_c('div',{staticClass:"viz-nav-btn__content"},[(_vm.element.data.namePrefix)?_c('Title',{staticClass:"viz-nav-btn__content__title",attrs:{"title":_vm.element.data.namePrefix}}):_vm._e(),_vm._v(" "+_vm._s(_vm.element.data.name)+" ")],1)])}
var staticRenderFns = []

export { render, staticRenderFns }