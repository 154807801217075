import { isEmpty } from 'lodash';

export default {
    methods: {
        resetQuery() {
            if (!isEmpty(this.$route.query)) {
                this.$router.replace({ query: null }); //TODO change  
            }
        },
        setQuery(type, id) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    type: type,
                    id: id,
                },
            });
        },
    },
};
