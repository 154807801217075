<template>
  <div class="constitution-kpis">
    <KPISection
      class="constitution-kpis__top"
      :title="content.KPIs.title"
      borderSide="right"
    >
      <template v-for="KPI of kpiData">
        <!-- Filter out the values that are 0 -->
        <KPI
          v-if="KPI.value"
          :key="KPI.label + KPI.value"
          class="constitution-kpis__top__kpi"
          :label="KPI.label"
          :bubbleColor="KPI.color"
          :bubbleBorderColor="KPI.borderColor"
          :value="KPI.value"
        />
      </template>
    </KPISection>

    <KPISection
      class="constitution-kpis__bottom"
      :title="content.kpiViz.title"
      borderSide="right"
    >
      <KPIViz
        v-for="kpiVizDatum of kpiVizData"
        :key="kpiVizDatum.label"
        class="constitution-kpis__bottom__kpiviz"
        :labels="content.kpiViz.labels"
        :kpiValue="{ label: kpiVizDatum.label, value: kpiVizDatum.value }"
        :dataMinMax="kpiVizDatum.dataMinMax"
        :totalAverage="kpiVizDatum.totalAverage"
      />
    </KPISection>

    <KPISection class="constitution-kpis__left" :title="content.filters.title">
      <ThemeFilter />
    </KPISection>

    <div class="constitution-kpis__banner" v-if="$tvaMq === 'desktop'">
      <p class="constitution-kpis__banner-text">Designed & developed by:</p>
      <img
        class="constitution-kpis__banner-logo"
        :src="`${$baseUrl}images/the-visual-agency-logo.png`"
        :alt="$t('general.alt.logoTVA')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import KPI from "./KPI.vue";
import KPISection from "./KPISection.vue";
import KPIViz from "./KPIViz.vue";
import ThemeFilter from "./ThemeFilter.vue";
import { getGeneralKPIs, getAverage } from "../utilities/functions/dataHelpers";

export default {
  components: {
    KPI,
    KPIViz,
    ThemeFilter,
    KPISection,
  },

  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      kpiVizMinMaxAverage: null,
      currentData: null,
    };
  },

  created() {
    this.kpiVizMinMaxAverage = this.getKpiVizMinMaxAverage();
    this.currentData = this.getCurrentData();
  },
  computed: {
    kpiData() {
      const values = this.getKPIValues(this.currentData);

      return values.map((value, i) => ({
        ...this.content.KPIs.values[i],
        value,
      }));
    },

    kpiVizData() {
      const values = this.getKpiVizValues(this.currentData);
      const minMaxAverage = this.kpiVizMinMaxAverage;

      return this.content.kpiViz.KPIs.map((label, i) => {
        return {
          label,
          value: values[i],
          dataMinMax: [minMaxAverage[i].min, minMaxAverage[i].max],
          totalAverage: minMaxAverage[i].average,
        };
      });
    },

    ...mapGetters([
      "getVizElSelected",
      "filteredData",
      "data",
      "getIsTransitioning",
    ]),
  },
  methods: {
    getCurrentData() {
      // If the user has selected a section in the viz, we get the data from getVizElSelected
      // If no selection has been made, getVizElSelected is empty and so we use all (filtered) data
      let data = this.filteredData;
      if (this.getVizElSelected) {
        data =
          this.getVizElSelected.data.articles ||
          this.getVizElSelected.data.value;
      }

      return data;
    },

    getKPIValues(data) {
      const abrogatedArticlesLength = data.filter(
        (datum) => datum.abrogated
      ).length;

      const disposizioniLength = data.filter(
        (datum) => datum.isDisposizione
      ).length;

      const articlesLength =
        data.length - abrogatedArticlesLength - disposizioniLength;

      return [articlesLength, abrogatedArticlesLength, disposizioniLength];
    },
    getKpiVizValues(data) {
      const averageWordsPerSentence = getAverage(
        data,
        "averageWordsPerSentence"
      );

      const averageParagraphsPerArticle = getAverage(data, "amountParagraphs");

      return [averageParagraphsPerArticle, averageWordsPerSentence];
    },

    getKpiVizMinMaxAverage() {
      // Get min, max and average kpis of entire dataset
      const {
        minParagraphsPerArticle,
        maxParagraphsPerArticle,
        averageParagraphsPerArticle,
        minWordCountPerSentence,
        maxWordCountPerSentence,
        averageWordCountPerSentence,
      } = getGeneralKPIs(this.data);

      const paragraphsMinMax = {
        min: minParagraphsPerArticle,
        max: maxParagraphsPerArticle,
        average: averageParagraphsPerArticle,
      };
      const wordCountMinMax = {
        min: minWordCountPerSentence,
        max: maxWordCountPerSentence,
        average: averageWordCountPerSentence,
      };

      return [paragraphsMinMax, wordCountMinMax];
    },
  },

  watch: {
    getIsTransitioning(value) {
      if (!value) {
        this.currentData = this.getCurrentData();
      }
    },
    filteredData(data) {
      this.currentData = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.constitution-kpis {
  display: grid;
  grid-template-columns: 1fr 1fr 25%;
  grid-template-areas:
    "top top left"
    "bottom bottom left";

  &__top {
    grid-area: top;

    ::v-deep .kpi-section__content {
      justify-content: flex-start;
    }

    &__kpi {
      margin-right: toRem(26);
    }
  }

  &__bottom {
    grid-area: bottom;

    // &__kpiviz:last-child {
    //   width: 50%;
    // }
  }

  &__left {
    grid-area: left;
    // max-height: toRem(391);
    padding-left: toRem(30);
    padding-right: 0;
  }
}

.desktop {
  .constitution-kpis {
    display: grid;
    grid-auto-flow: row ;
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    max-width: toRem(350);
    // max-height: 100%;
    height: calc(100vh - calc(50 / 16) * 1rem);
    overflow: auto;
    &__banner {
      // height: 100%;
      border-right: toRem(2) solid var(--primary-light-40);
      padding: toRem(14) 0;
      grid-area: 4 / 1 / 5 / 2;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      &-text {
        font-family: Inter;
        font-size: toRem(12);
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: toRem(4);
      }

      &-logo {
        width: toRem(223);
      }
    }

    &__top {
      display: flex;
      flex-direction: column;
      position: relative;
      border-bottom: none;
      grid-area: 1 / 1 / 2 / 2;
      padding-top: toRem(8);
      // min-height: toRem(140);

      &::after {
        content: "";
        position: absolute;
        height: toRem(2);
        width: calc(100% - toRem(14));
        background-color: var(--primary-light-40);
        bottom: toRem(-2);
      }
    }

    &__bottom {
      margin: 0;
      border-bottom: none;
      grid-area: 2 / 1 / 3 / 2;

      &__kpiviz {
        &:not(:first-of-type) {
          margin-top: 1.5rem;
        }
      }
    }

    &__left {
      grid-area: 3 / 1 / 4 / 2;
      @include shadow-1;
      transform: translateX(toRem(-60));
      width: calc(100% + toRem(60) + toRem(10));
      border-radius: 0px 10px 10px 0px;
      padding: 0;
      padding-left: toRem(60);
      padding-top: toRem(8);
    }
  }
}
</style>
